<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("info_ot.title") }}</h1>
      </div>
      <v-breadcrumbs-item class="breadcrumbs-hover" v-if="$route.name === 'employee_ot.edit'">
        <span v-show="true">/</span>
        <span>{{ $t("employee_ot.edit") }}</span>
      </v-breadcrumbs-item>
    </div>
    <hr />
    <br />
    <div class="section-action">
      <v-row class="mb-8 ml-2">
        <v-col cols="12" md="2" lg="2">
          <label class="label-input">{{ $t("ot_report.year") }}</label>
          <v-select :items="years" outlined dense v-model="year" @change="fetchEmployeeConcludeOt"></v-select>
        </v-col>
        <v-col cols="12" md="2" lg="2">
          <label class="label-input">{{ $t("ot_report.month") }}</label>
          <v-select :items="months" outlined dense v-model="month" @change="fetchEmployeeConcludeOt"></v-select>
        </v-col>
        <v-col cols="4" md="4" lg="4">
          <label class="label-input">{{
            $t("employee_ot.search_title")
          }}</label>
          <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense v-model="searchItem"
            prepend-inner-icon="mdi-magnify" :placeholder="$t('Search.search')" @keypress.enter="searchFilterItem">
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="listEmployeeConcludeOt.length > 0">
          <thead>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">
                {{ $t("info_ot.table.emp_number") }}
              </th>
              <th class="text-left">
                {{ $t("info_ot.table.emp_name") }}
              </th>
              <th class="text-left">
                {{ $t("info_ot.table.dept") }}
              </th>
              <th class="text-left">
                {{ $t("info_ot.table.branch") }}
              </th>
              <th class="text-left">
                {{ $t("info_ot.table.total_ot") }}
              </th>
              <th class="text-left">
                {{ $t("info_ot.table.total_ot_receive") }}
              </th>
              <th class="text-left">
                {{ $t("info_ot.table.using_ot") }}
              </th>
              <th class="text-left">
                {{ $t("info_ot.table.Remaining_ot") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listEmployeeConcludeOt" :key="idx">
              <td>{{ idx + 1 }}</td>
              <td>{{ item.emp_number }}</td>
              <td>{{ item.name }} {{ item.surname }}</td>
              <td>{{ item.department }}</td>
              <td>{{ item.branch }}</td>
              <td class="text-success">
                <span v-if="item.conclude_ot.qty_working.days > 0">
                  {{ item.conclude_ot.qty_working.days }}
                  {{ $t("info_ot.table.day") }}
                </span>
                <span v-if="item.conclude_ot.qty_working.hours > 0">
                  {{ item.conclude_ot.qty_working.hours }}
                  {{ $t("info_ot.table.hour") }}
                </span>
                <span v-if="item.conclude_ot.qty_working.minutes > 0">
                  {{ item.conclude_ot.qty_working.minutes }}
                  {{ $t("info_ot.table.minute") }}
                </span>
              </td>
              <td class="text-primary">
                <span v-if="item.conclude_ot.qty_receive.days > 0">
                  {{ item.conclude_ot.qty_receive.days }}
                  {{ $t("info_ot.table.day") }}
                </span>
                <span v-if="item.conclude_ot.qty_receive.hours > 0">
                  {{ item.conclude_ot.qty_receive.hours }}
                  {{ $t("info_ot.table.hour") }}
                </span>
                <span v-if="item.conclude_ot.qty_receive.minutes > 0">
                  {{ item.conclude_ot.qty_receive.minutes }}
                  {{ $t("info_ot.table.minute") }}
                </span>
              </td>
              <td class="text-danger">
                <span v-if="item.conclude_ot.qty_using.days > 0">
                  {{ item.conclude_ot.qty_using.days }}
                  {{ $t("info_ot.table.day") }}
                </span>
                <span v-if="item.conclude_ot.qty_using.hours > 0">
                  {{ item.conclude_ot.qty_using.hours }}
                  {{ $t("info_ot.table.hour") }}
                </span>
                <span v-if="item.conclude_ot.qty_using.minutes > 0">
                  {{ item.conclude_ot.qty_using.minutes }}
                  {{ $t("info_ot.table.minute") }}
                </span>
              </td>
              <td class="text-warning">
                <span v-if="item.conclude_ot.qty_remaining.days > 0">
                  {{ item.conclude_ot.qty_remaining.days }}
                  {{ $t("info_ot.table.day") }}
                </span>
                <span v-if="item.conclude_ot.qty_remaining.hours > 0">
                  {{ item.conclude_ot.qty_remaining.hours }}
                  {{ $t("info_ot.table.hour") }}
                </span>
                <span v-if="item.conclude_ot.qty_remaining.minutes > 0">
                  {{ item.conclude_ot.qty_remaining.minutes }}
                  {{ $t("info_ot.table.minute") }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else />
        <!-- <ModalDelete fullscreen title="Edit Employee Leave">
          <template v-slot="{ close }">
            <Delete
              :employee_ot_id="employee_ot_id"
              @close="close"
              @success="fetchEmployeeOt()"
            />
          </template>
        </ModalDelete> -->
        <Pagination v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
          @paginate="fetchEmployeeConcludeOt">
        </Pagination>
        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Pagination from "../../../components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import Loading from "@/components/Loading";

import "vue2-datepicker/index.css";
export default {
  components: {
    DatePicker,
    Pagination,
    defaultTableNoResult,
    Loading,
  },
  data() {
    return {
      offset: 10,
      pagination: {},
      employeeOt: {},
      per_page: 10,
      isLoading: true,
      listEmployeeConcludeOt: [],
      searchItem: "",
      isLoading: true,
      year: new Date().getFullYear(),
      month: "",
      months: [],
    };
  },
  methods: {
    getYears() {
      let year = this.year - 4;
      let years = [];
      for (let i = 5; i > 1; i--) {
        years.push(year + i);
      }
      this.years = years;
    },
    searchFilterItem() {
      this.fetchEmployeeConcludeOt();
      this.pagination.current_page = 1;
    },

    fetchEmployeeConcludeOt() {
      this.isLoading = true;
      this.$axios
        .get(`company/conclude/employee/ot`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.searchItem,
            year: this.year,
            month: this.month,
          },
        })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.isLoading = false;
            this.listEmployeeConcludeOt = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.listEmployeeConcludeOt.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployeeConcludeOt();
    this.getYears();
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 20px;
}
</style>
